/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import Container from '../../components/elements/Container';

import LogsProvider from '../../context/LogContext';

import LogsList from './LogsList';

export default function Logs() {
  return (
    <Container>
      <LogsProvider>
        <LogsList />
      </LogsProvider>
    </Container>
  );
}
