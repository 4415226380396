import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import Card from '../../components/elements/Card';
import Overlay from '../../components/Overlay';

import history from '../../history';

import { getBancos } from '../../webReferences/bancoAPI';

import { formatSearch } from '../../helpers/Utils';

import { ReactComponent as BankIcon } from '../../assets/svg/bank.svg';

import './styles.scss';

export default function BancosList() {
  const [loading, setLoading] = useState(false);
  const [bancos, setBancos] = useState([]);
  const [apiList, setApiList] = useState([]);

  const adicionarBanco = () => history.push('/banco');

  useEffect(() => {
    async function loadBancos() {
      setLoading(true);
      const ret = await getBancos();

      setBancos(ret.data || []);
      setApiList(ret.data || []);
      setLoading(false);
    }

    loadBancos();
  }, []);

  const RenderThead = () => (
    <tr className="text-uppercase">
      <th>Código Febraban</th>
      <th style={{ minWidth: 200 }}>Descrição</th>
      <th className="pr-0 text-right" style={{ minWidth: 160 }}>
        Detalhes
      </th>
    </tr>
  );

  const RenderTrs = () => {
    return bancos?.map(({ bancoId, codigoFebraBan, descricao }) => (
      <tr key={bancoId}>
        <td>
          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
            {codigoFebraBan}
          </span>
        </td>
        <td>
          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
            {descricao}
          </span>
        </td>

        <td className="pr-0 text-right">
          <Link
            to={`banco/${bancoId}`}
            className="btn btn-icon btn-outline-success btn-circle btn-sm mr-2"
          >
            <i className="fas fa-search-plus"></i>
          </Link>
        </td>
      </tr>
    ));
  };

  const handleSearch = (search) => {
    const find = apiList.filter(
      ({ codigoFebraBan, descricao }) =>
        formatSearch(codigoFebraBan).includes(formatSearch(search)) ||
        formatSearch(descricao).includes(formatSearch(search)),
    );

    setBancos(find);
  };

  return (
    <Overlay active={loading}>
      <Card>
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Bancos
            </span>
            <span className="text-dark mt-3 font-weight-bold font-size-sm">
              Você está visualizando <b>{bancos?.length} bancos</b>
            </span>
          </h3>
          <div className="d-flex col-lg-5">
            <input
              type="text"
              placeholder="Pesquisar na tabela"
              className="form-control"
              onChange={(e) => handleSearch(e.target.value)}
            />
            <button
              type="button"
              className="btn btn-success form-control col-lg-4 ml-2"
              onClick={() => adicionarBanco()}
            >
              <BankIcon /> Adicionar
            </button>
          </div>
        </div>
        <div className="list-propostas">
          <div className="card-body py-0">
            <div className="table-responsive">
              <table
                className="table table-head-custom table-vertical-center"
                id="kt_advance_table_widget_2"
              >
                <thead>
                  <RenderThead />
                </thead>
                <tbody>
                  <RenderTrs />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Card>
    </Overlay>
  );
}
