/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react';

import { Link } from 'react-router-dom';

import Card from '../../components/elements/Card';
import Overlay from '../../components/Overlay';

import { LogContext } from '../../context/LogContext';

import './styles.scss';

export default function ParceirosList() {
  const { logs, loading } = useContext(LogContext);


  // const params = useParams();
  // const limit = Number(params?.limit);
  // const where = params?.where;


  const RenderThead = () => (
    <tr className="text-uppercase">
      <th>Data</th>
      <th>Mensagem</th>
      <th>Exception</th>
    </tr>
  );

  const RenderTrs = () => {
    return logs?.map(
      ({
        date,
        message,
        exception,
      }) => (
        <tr >
          <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-sm">
              {date}
            </span>
           
          </td>
          <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-sm">
              {message}
            </span>
          </td>
          <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-sm">
              {exception}
            </span>
          </td>

        </tr>
      ),
    );
  };

  return (
    <Overlay active={loading}>
      <Card>
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Logs
            </span>
            <span className="text-dark mt-3 font-weight-bold font-size-sm">
              Você está visualizando <b>{logs?.length} logs</b>
            </span>
          </h3>
        </div>
        <div className="list-propostas">
          <div className="card-body py-0">
            <div className="table-responsive">
              <table
                className="table table-head-custom table-vertical-center"
                id="kt_advance_table_widget_2"
              >
                <thead>
                  <RenderThead />
                </thead>
                <tbody>
                  <RenderTrs />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Card>
    </Overlay>
  );
}
