import { object, string } from 'yup';

const validationSchema = object().shape({
  sigla: string().required('A sigla é obrigatória').nullable(),
  descricao: string().required('A descrição é obrigatória').nullable(),
  cidade: string().required('A cidade é obrigatória').nullable(),
  estado: string().required('O estado é obrigatório').nullable(),
  pais: string().required('O país é obrigatório').nullable(),
});

export default validationSchema;
