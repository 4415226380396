import React, { createContext, useState, useEffect, useCallback } from 'react';
import { getParceiros } from '../webReferences/parceirosAPI';

export const ParceiroContext = createContext({
  loading: false,
  parceiros: [],
});

export default function ParceiroProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [parceiros, setParceiros] = useState([]);

  const loadParceiros = useCallback(async () => {
    setLoading(true);
    const ret = await getParceiros().catch(() => setLoading(false));

    setParceiros(ret?.data || []);
    setLoading(false);
  }, []);

  useEffect(() => {
    loadParceiros();
  }, [loadParceiros]);

  return (
    <ParceiroContext.Provider value={{ loading, parceiros }}>
      {children}
    </ParceiroContext.Provider>
  );
}
