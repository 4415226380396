import React, { useEffect, useState } from "react";
import { Formik, ErrorMessage, Field, Form } from "formik";
import cep from "cep-promise";

import { getUsuario, atualizarUsuario } from "../../webReferences/userAPI";

import InputMask from "react-input-mask";

import validationSchema from "./validationSchema";
import initialValues from "./initialValues";

import Container from "../../components/elements/Container";
import Card from "../../components/elements/Card";
import Overlay from "../../components/Overlay";
import Modal from "../../components/Modal";

export default function Perfil() {
  const [formData, setFormData] = useState(initialValues);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const { usuarioId } = userData.data;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadUsuario() {
      setLoading(true);
      const ret = await getUsuario(usuarioId);

      setFormData((formData) => ({
        ...formData,
        ...(ret.data || initialValues),
      }));
      setLoading(false);
    }

    loadUsuario();
  }, [usuarioId]);

  const handleCep = (e, form) => {
    const { setFieldTouched, setValues, setFieldError, values } = form;

    setFieldTouched("endereco.cep", true);

    let { value } = e.target;
    value = value.replace(/\D/g, "");

    cep(value)
      .then((cepData) => {
        setValues({
          ...values,
          endereco: {
            ...values.endereco,
            bairro: cepData.neighborhood,
            logradouro: cepData.street,
            estado: cepData.state,
            cidade: cepData.city,
          },
        });
      })
      .catch(() => {
        setFieldError("endereco.cep", "CEP não encontrado");

        setValues({
          ...values,
          endereco: {
            ...values.endereco,
            bairro: "",
            logradouro: "",
            estado: "",
            cidade: "",
          },
        });
      });
  };

  return (
    <Container>
      <Overlay active={loading} marginTop={300}>
        <Card>
          <div className="card-body p-0">
            <div className="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
              <div className="col-xl-12 col-xxl-7">
                <Formik
                  initialValues={formData}
                  validationSchema={validationSchema}
                  validateOnBlur={true}
                  enableReinitialize={true}
                  onSubmit={async (data) => {
                    setLoading(true);

                    const {
                      usuarioId,
                      tipoUsuarioId,
                      nomeUsuario,
                      email,
                      celular,
                      telefoneFixo,
                      telefoneComercial,
                      endereco,
                      senha,
                    } = data;

                    const payload = {
                      nomeUsuario,
                      email,
                      celular,
                      telefoneFixo,
                      telefoneComercial,
                      endereco,
                      senha,
                      usuarioId,
                      tipoUsuarioId,
                    };

                    const response = await atualizarUsuario(payload).catch(() =>
                      setLoading(false)
                    );

                    if (response?.sucesso) {
                      setLoading(false);

                      Modal({
                        text: "Perfil atualizado com sucesso!",
                        icon: "success",
                      });
                    }
                  }}
                >
                  {({ values }) => (
                    <Form>
                      <h2 className="mb-10 font-weight-bolder">
                        Edição de dados
                      </h2>
                      <div className="form-group">
                        <h4 className="mb-10 font-weight-bold text-primary">
                          <i className="far fa-edit text-primary"></i> Dados
                          Pessoais
                        </h4>
                      </div>
                      <div className="form-group">
                        <label>Nome Completo</label>
                        <Field
                          name="nomeUsuario"
                          className="form-control form-control-solid form-control-lg"
                          placeholder="Digite seu nome"
                        />
                        <span className="form-text text-muted">
                          Por favor preencha seu nome
                        </span>
                        <ErrorMessage name="nomeUsuario" component="small" />
                      </div>

                      <div className="row">
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>E-mail</label>
                            <Field
                              name="email"
                              className="form-control form-control-solid form-control-lg"
                              placeholder="Digite seu e-mail"
                            />

                            <span className="form-text text-muted">
                              Por favor preencha seu e-mail
                            </span>
                            <ErrorMessage name="email" component="small" />
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Celular</label>
                            <Field name="celular">
                              {({ field }) => (
                                <InputMask
                                  {...field}
                                  className="form-control form-control-solid form-control-lg"
                                  mask="(99) 99999-9999"
                                  placeholder="Digite seu celular"
                                />
                              )}
                            </Field>

                            <span className="form-text text-muted">
                              Por favor preencha seu celular
                            </span>
                            <ErrorMessage name="celular" component="small" />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Telefone Fixo</label>
                            <Field name="telefoneFixo">
                              {({ field }) => (
                                <InputMask
                                  {...field}
                                  className="form-control form-control-solid form-control-lg"
                                  mask="(99) 9999-9999"
                                  placeholder="Digite seu telefone fixo"
                                />
                              )}
                            </Field>
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Telefone Comercial</label>
                            <Field name="telefoneComercial">
                              {({ field }) => (
                                <InputMask
                                  {...field}
                                  className="form-control form-control-solid form-control-lg"
                                  mask="(99) 9999-9999"
                                  placeholder="Digite seu telefone comercial"
                                />
                              )}
                            </Field>
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <h4 className="mb-10 font-weight-bold text-primary">
                          <i className="far fa-edit text-primary"></i> Endereço
                        </h4>
                      </div>
                      <div className="row">
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Digite seu CEP</label>

                            <Field name="endereco.cep">
                              {({ field, form }) => (
                                <InputMask
                                  {...field}
                                  className="form-control form-control-solid form-control-lg"
                                  mask="99999-999"
                                  placeholder="Digite seu CEP"
                                  onBlur={(e) => handleCep(e, form)}
                                />
                              )}
                            </Field>

                            <span className="form-text text-muted">
                              Por favor preencha seu CEP
                            </span>
                            <ErrorMessage
                              name="endereco.cep"
                              component="small"
                            />
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Bairro</label>
                            <Field
                              name="endereco.bairro"
                              className="form-control form-control-solid form-control-lg"
                              placeholder="Digite seu bairro"
                              disabled={values.endereco?.cep}
                            />
                            <span className="form-text text-muted">
                              Por favor preencha seu bairro
                            </span>
                            <ErrorMessage
                              name="endereco.bairro"
                              component="small"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Logradouro</label>
                        <Field
                          name="endereco.logradouro"
                          className="form-control form-control-solid form-control-lg"
                          placeholder="Digite seu logradouro"
                          disabled={values.endereco?.cep}
                        />

                        <span className="form-text text-muted">
                          Por favor preencha seu logradouro
                        </span>
                        <ErrorMessage
                          name="endereco.logradouro"
                          component="small"
                        />
                      </div>
                      <div className="row">
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Estado</label>
                            <Field
                              name="endereco.estado"
                              className="form-control form-control-solid form-control-lg"
                              placeholder="Digite seu estado"
                              disabled={values.endereco?.cep}
                            />

                            <span className="form-text text-muted">
                              Por favor preencha seu estado
                            </span>
                            <ErrorMessage
                              name="endereco.estado"
                              component="small"
                            />
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Cidade</label>
                            <Field
                              name="endereco.cidade"
                              className="form-control form-control-solid form-control-lg"
                              placeholder="Digite sua cidade"
                              disabled={values.endereco?.cep}
                            />

                            <span className="form-text text-muted">
                              Por favor preencha seu cidade
                            </span>
                            <ErrorMessage
                              name="endereco.cidade"
                              component="small"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Número</label>
                            <Field
                              name="endereco.numero"
                              className="form-control form-control-solid form-control-lg"
                              placeholder="Digite o número"
                            />

                            <span className="form-text text-muted">
                              Por favor preencha o número
                            </span>
                            <ErrorMessage
                              name="endereco.numero"
                              component="small"
                            />
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Complemento</label>
                            <Field
                              name="endereco.complemento"
                              className="form-control form-control-solid form-control-lg"
                              placeholder="Digite o complemento"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <h4 className="mb-10 font-weight-bold text-primary">
                          <i className="far fa-edit text-primary"></i> Alterar
                          Senha
                        </h4>
                      </div>
                      <div className="row">
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Nova Senha</label>
                            <Field
                              name="senha"
                              type="password"
                              className="form-control form-control-solid form-control-lg"
                              placeholder="Digite sua nova senha"
                            />
                            <ErrorMessage name="senha" component="small" />
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Redigir Senha</label>
                            <Field
                              name="confirmarSenha"
                              type="password"
                              className="form-control form-control-solid form-control-lg"
                              placeholder="Confirme sua nova senha"
                            />
                            <ErrorMessage
                              name="confirmarSenha"
                              component="small"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end border-top mt-5 pt-10">
                        <button
                          type="submit"
                          className="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
                        >
                          Salvar
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Card>
      </Overlay>
    </Container>
  );
}
