import { object, number } from 'yup';

const validationSchema = object().shape({
  taxaCliente: number().required('A taxa cliente é obrigatória').nullable(),
  quantidadeParcelamentoMinimo: number()
    .required('O parcelamento mínimo é obrigatório')
    .nullable(),
  quantidadeParcelamentoMaximo: number()
    .required('O parcelamento máximo é obrigatório')
    .nullable(),
  valorMinimoFinanciamento: number()
    .required('O valor mínimo é obrigatório')
    .nullable(),
  valorMaximoFinanciamento: number()
    .required('O valor máximo é obrigatório')
    .nullable(),
  tacPercentual: number().required('A TAC é obrigatória').nullable(),
  tacValorReais: number().required('A TAC é obrigatória').nullable(),
  planoComOuSemEntrada: number().required('O plano é obrigatório').nullable(),
  taxaAdmin: number().required('A taxa de ADM é obrigatória').nullable(),
  taxaAntecipacao: number()
    .required('A taxa de antecipação é obrigatória')
    .nullable(),
  valorMensalidade: number().required('A mensalidade é obrigatória').nullable(),
});

export default validationSchema;
