import React, { createContext, useState, useEffect, useCallback } from 'react';
import { getPropostas } from '../webReferences/propostasAPI';
import { subDays } from 'date-fns';

export const PropostaContext = createContext({
  loading: false,
  propostas: [],
  setFilters: (f) => f,
});

export default function PropostasProvider({ children }) {
  const userData = JSON.parse(localStorage.getItem('userData'));
  const { parceiroId } = userData.data;

  const INITIAL_FILTERS = {
    parceiroId,
    nomeCliente: '',
    nomeFantasia: '',
    cpfCliente: '',
    email: '',
    celular: '',
    statusPropostaId: 0,
    dataInicio: subDays(new Date(), 30),
    dataFim: new Date(),
  };

  const [loading, setLoading] = useState(false);
  const [propostas, setPropostas] = useState([]);
  const [filters, setFilters] = useState(INITIAL_FILTERS);

  const loadPropostas = useCallback(async () => {
    setLoading(true);
    const ret = await getPropostas(filters).catch(() => setLoading(false));

    setPropostas(ret?.data || []);
    setLoading(false);
  }, [filters]);

  useEffect(() => {
    loadPropostas();
  }, [loadPropostas]);

  return (
    <PropostaContext.Provider value={{ loading, propostas, setFilters }}>
      {children}
    </PropostaContext.Provider>
  );
}
