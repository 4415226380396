/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import Container from '../../components/elements/Container';

import ParceirosProvider from '../../context/ParceiroContext';

import ParceirosList from './ParceirosList';

export default function Parceiros() {
  return (
    <Container>
      <ParceirosProvider>
        <ParceirosList />
      </ParceirosProvider>
    </Container>
  );
}
