import api from './api';

export const getAeroportos = async () => {
  const { data } = await api.get('/aeroporto/ListAeroportos');
  return data;
};

export const getAeroportoById = async (aeroportoId) => {
  const { data } = await api.get(
    `/aeroporto/AeroportoGetById?aeroportoId=${aeroportoId}`,
  );
  return data;
};

export const cadastrarAeroporto = async (req) => {
  const { data } = await api.post('/aeroporto/CadastrarAeroporto', req);
  return data;
};

export const atualizarAeroporto = async (req) => {
  const { data } = await api.post('/aeroporto/AtualizarAeroporto', req);
  return data;
};
