/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect } from 'react';

import { Context } from '../../context/AuthContext';

import { cpfMask } from '../../helpers/Utils';
import { cpf } from 'cpf-cnpj-validator';

import SpinnerLoading from '../../components/SpinnerLogin';

import Header from './Header';
import Footer from './Footer';
import ModalRecuperarSenha from './ModalRecuperarSenha';

import './styles.scss';

export default function Login() {
  const { handleLogin } = useContext(Context);
  const [inputCpf, setInputCpf] = useState(null);
  const [password, setPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [erroLogin, setErroLogin] = useState(false);
  const [erroCredenciais, setErroCredenciais] = useState(false);
  const [cpfInvalido, setCpfInvalido] = useState(false);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [showComponent, setShowComponent] = useState(false);
  const [etapaTrocaSenha, setEtapaTrocaSenha] = useState(null);

  const modalToggle = () => setModalVisible(!modalVisible);

  function handleChange(e) {
    setCpfInvalido(false);
    const { id, value } = e.target;
    setErroLogin(false);
    setErroCredenciais(false);

    switch (id) {
      case 'cpf':
        setErrors({ ...errors, cpf: 'Digite seu CPF' });
        setInputCpf(cpfMask(value));
        break;
      case 'password':
        setErrors({ ...errors, senha: 'Digite sua senha' });
        setPassword(value);
        break;
      default:
    }
  }

  const setFieldTouched = (e) => {
    const { id } = e.target;

    switch (id) {
      case 'cpf':
        setTouched({ ...touched, cpf: true });
        break;
      case 'password':
        setTouched({ ...touched, senha: true });
        break;
      default:
    }
  };

  async function handleValidate(e) {
    e.preventDefault();
    setErroLogin(false);
    setErroCredenciais(false);

    if (!inputCpf && !password) {
      setTouched({ ...touched, cpf: true, senha: true });
      setErrors({
        ...errors,
        cpf: 'Digite seu CPF',
        senha: 'Digite sua senha',
      });

      return;
    }

    if (!inputCpf) {
      setTouched({ ...touched, cpf: true });
      setErrors({ ...errors, cpf: 'Digite seu CPF' });
    } else if (!cpf.isValid(inputCpf)) {
      setTouched({ ...touched, cpf: true });
      setErrors({ ...errors, cpf: 'CPF Inválido' });
      setCpfInvalido(true);
    } else if (!password) {
      setTouched({ ...touched, senha: true });
      setErrors({ ...errors, senha: 'Digite sua senha' });
    } else {
      setLoading(true);
      const data = await handleLogin(inputCpf, password);

      if (!data?.data) {
        setErroLogin(true);
        setLoading(false);
        return;
      }

      if (!data?.data.autenticado) {
        setErroCredenciais(true);
        setLoading(false);
      }

      if (data?.data?.redirecionarTrocaDeSenha) {
        setLoading(false);
        setEtapaTrocaSenha(3);
        modalToggle();
      }
    }
  }

  useEffect(() => {
    localStorage.clear();
    const plugins = document.createElement('script');
    const theme = document.createElement('script');

    plugins.src = '/assets/js/plugins.js';
    theme.src = '/assets/js/theme.js';

    async function loadStyles() {
      const promiseCss = () =>
        new Promise((resolve) => {
          resolve(import('../../assets/css/style.login.css'));
        });

      const promiseScripts = () =>
        new Promise((resolve) => {
          resolve(
            document.body.appendChild(plugins),
            document.body.appendChild(theme),
          );
        });

      const promises = [promiseCss, promiseScripts];

      for (let promise of promises) {
        await promise();
      }
    }

    loadStyles().then(() => {
      setShowComponent(true);
    });
  }, []);

  return (
    showComponent && (
      <div className="login">
        <div className="content-wrapper">
          <Header />

          <section className="wrapper bg-soft-primary">
            <div className="container pt-10 pb-15 pt-md-14 pb-md-10 text-center">
              <div className="row">
                <div
                  className="col-md-10 col-lg-8 col-xl-8 col-xxl-4 mx-auto mb-13"
                  data-cues="slideInDown"
                  data-group="page-title"
                >
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                  <h3 className="mb-4">Admin VoePay</h3>
                  <form className="text-start mb-3">
                    <div className="form-label-group mb-4">
                      <input
                        type="text"
                        className={`form-control ${
                          (!inputCpf || cpfInvalido) &&
                          errors.cpf &&
                          touched.cpf &&
                          'required'
                        }`}
                        placeholder="CPF"
                        id="cpf"
                        value={inputCpf}
                        onChange={handleChange}
                        onFocus={setFieldTouched}
                      />
                      <label htmlFor="cpf">Digite seu CPF</label>
                      <small>
                        {(!inputCpf || cpfInvalido) &&
                          touched.cpf &&
                          errors.cpf}
                      </small>
                    </div>
                    <div className="form-label-group mb-4">
                      <input
                        type="password"
                        className={`form-control ${
                          !password &&
                          errors.senha &&
                          touched.senha &&
                          'required'
                        }`}
                        id="password"
                        placeholder="Password"
                        value={password}
                        onChange={handleChange}
                        onFocus={setFieldTouched}
                      />
                      <label htmlFor="password">Digite sua senha</label>
                      <small>
                        {!password && touched.senha && errors.senha}
                      </small>
                    </div>
                    {erroCredenciais && (
                      <small className="erro-login mb-4">
                        Usuário ou senha incorretos
                      </small>
                    )}
                    {erroLogin && (
                      <small className="erro-login mb-4">Falha no login</small>
                    )}

                    <button
                      className="btn btn-primary rounded-pill btn-login w-100 mb-2"
                      onClick={(e) => handleValidate(e)}
                      disabled={loading}
                    >
                      {loading ? <SpinnerLoading /> : 'Acessar VoePay'}
                    </button>
                  </form>
                  {/* <!-- /form --> */}
                  <p className="mb-1">
                    <a href="#" onClick={() => modalToggle()} className="hover">
                      Esqueceu sua senha?
                    </a>
                  </p>
                  <div className="divider-icon my-4">ou</div>

                  <div
                    className="d-flex justify-content-center"
                    data-cues="slideInDown"
                    data-group="page-title-buttons"
                    data-delay="600"
                  >
                    <span>
                      <a className="btn btn-primary rounded mx-1">
                        Falar com a VoePay
                      </a>
                    </span>
                    <span>
                      <a className="btn btn-green rounded mx-1">
                        Encontrar um Parceiro
                      </a>
                    </span>
                  </div>
                </div>
                {/* <!-- /column --> */}
              </div>
              {/* <!-- /.row --> */}
            </div>
            {/* <!-- /.container --> */}
          </section>
        </div>

        <Footer />

        <ModalRecuperarSenha
          inputCpf={inputCpf}
          setInputCpf={setInputCpf}
          modalVisible={modalVisible}
          modalToggle={modalToggle}
          etapaTrocaSenha={etapaTrocaSenha}
          setEtapaTrocaSenha={setEtapaTrocaSenha}
        />

        <div className="progress-wrap">
          <svg
            className="progress-circle svg-content"
            width="100%"
            height="100%"
            viewBox="-1 -1 102 102"
          >
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
          </svg>
        </div>
      </div>
    )
  );
}
