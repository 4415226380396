import React, { useEffect, useState } from 'react';
import { Formik, ErrorMessage, Field, Form } from 'formik';
import { useParams } from 'react-router-dom';
import Select from 'react-select';

import {
  getConfiguracoes,
  atualizarConfiguracoes,
} from '../../webReferences/configAPI';

import InputNumber from '../../components/InputNumber';

import validationSchema from './validationSchema';
import initialValues from './initialValues';

import Container from '../../components/elements/Container';
import Card from '../../components/elements/Card';
import Overlay from '../../components/Overlay';
import Modal from '../../components/Modal';

const planos = [
  {
    label: 'Sem entrada',
    value: 0,
  },
  {
    label: 'Com entrada',
    value: 1,
  },
];

export default function ConfiguracoesParceiro() {
  const params = useParams();
  const parceiroId = Number(params?.parceiroId);

  const [formData, setFormData] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadConfiguracoes() {
      setLoading(true);
      const ret = await getConfiguracoes(parceiroId);

      setFormData((formData) => ({
        ...formData,
        ...(ret.data || initialValues),
      }));
      setLoading(false);
    }

    loadConfiguracoes();
  }, [parceiroId]);

  return (
    <Container>
      <Overlay active={loading} marginTop={300}>
        <Card>
          <div className="card-body p-0">
            <div className="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
              <div className="col-xl-12 col-xxl-7">
                <Formik
                  initialValues={formData}
                  validationSchema={validationSchema}
                  validateOnBlur={true}
                  enableReinitialize={true}
                  onSubmit={async (data) => {
                    setLoading(true);

                    const {
                      configuracoesParceiroId,
                      taxaCliente,
                      quantidadeParcelamentoMinimo,
                      quantidadeParcelamentoMaximo,
                      valorMinimoFinanciamento,
                      valorMaximoFinanciamento,
                      tacPercentual,
                      tacValorReais,
                      planoComOuSemEntrada,
                      taxaAdmin,
                      taxaAntecipacao,
                      valorMensalidade,
                    } = data;

                    const payload = {
                      taxaCliente,
                      quantidadeParcelamentoMinimo,
                      quantidadeParcelamentoMaximo,
                      valorMinimoFinanciamento,
                      valorMaximoFinanciamento,
                      tacPercentual,
                      tacValorReais,
                      planoComOuSemEntrada,
                      taxaAdmin,
                      taxaAntecipacao,
                      valorMensalidade,
                      parceiroId,
                      configuracoesParceiroId,
                    };

                    const response = await atualizarConfiguracoes(
                      payload,
                    ).catch(() => setLoading(false));

                    if (response?.sucesso) {
                      setLoading(false);

                      Modal({
                        text: 'Configurações atualizadas com sucesso!',
                        icon: 'success',
                      });
                    }
                  }}
                >
                  {({ values, setFieldValue }) => (
                    <Form>
                      <h2 className="mb-10 font-weight-bolder">
                        Configurações
                      </h2>
                      <div className="form-group">
                        <h4 className="mb-10 font-weight-bold text-primary">
                          <i className="far fa-edit text-primary"></i>{' '}
                          Informações do parceiro
                        </h4>
                      </div>

                      <div className="form-group">
                        <label>Taxa cliente</label>

                        <Field name="taxaCliente">
                          {({ field }) => (
                            <InputNumber
                              {...field}
                              onChange={(f) => f}
                              className="form-control form-control-solid form-control-lg"
                              placeholder="Digite a Taxa Cliente"
                              allowNegative={false}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              maxLength={8}
                              suffix=" %"
                              value={values.taxaCliente}
                              onValueChange={(values) => {
                                setFieldValue('taxaCliente', values.floatValue);
                              }}
                            />
                          )}
                        </Field>

                        <span className="form-text text-muted">
                          Taxa aplicada no cálculo das parcelas do cliente.
                        </span>
                        <ErrorMessage name="taxaCliente" component="small" />
                      </div>

                      <div className="row">
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Parcelamento mínimo</label>

                            <Field name="quantidadeParcelamentoMinimo">
                              {({ field }) => (
                                <InputNumber
                                  {...field}
                                  onChange={(f) => f}
                                  className="form-control form-control-solid form-control-lg"
                                  placeholder="Digite o parcelamento mínimo"
                                  allowNegative={false}
                                  maxLength={2}
                                  value={values.quantidadeParcelamentoMinimo}
                                  onValueChange={(values) => {
                                    setFieldValue(
                                      'quantidadeParcelamentoMinimo',
                                      values.floatValue,
                                    );
                                  }}
                                />
                              )}
                            </Field>

                            <span className="form-text text-muted">
                              Parcelamento mínimo
                            </span>
                            <ErrorMessage
                              name="quantidadeParcelamentoMinimo"
                              component="small"
                            />
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Parcelamento máximo</label>

                            <Field name="quantidadeParcelamentoMaximo">
                              {({ field }) => (
                                <InputNumber
                                  {...field}
                                  onChange={(f) => f}
                                  className="form-control form-control-solid form-control-lg"
                                  placeholder="Digite o parcelamento mínimo"
                                  allowNegative={false}
                                  maxLength={2}
                                  value={values.quantidadeParcelamentoMaximo}
                                  onValueChange={(values) => {
                                    setFieldValue(
                                      'quantidadeParcelamentoMaximo',
                                      values.floatValue,
                                    );
                                  }}
                                />
                              )}
                            </Field>

                            <span className="form-text text-muted">
                              Parcelamento máximo
                            </span>
                            <ErrorMessage
                              name="quantidadeParcelamentoMaximo"
                              component="small"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Valor mínimo</label>
                            <Field name="valorMinimoFinanciamento">
                              {({ field }) => (
                                <InputNumber
                                  {...field}
                                  onChange={(f) => f}
                                  className="form-control form-control-solid form-control-lg"
                                  placeholder="Digite a TAC"
                                  prefix="R$ "
                                  allowNegative={false}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  maxLength={10}
                                  value={values.valorMinimoFinanciamento}
                                  onValueChange={(values) => {
                                    setFieldValue(
                                      'valorMinimoFinanciamento',
                                      values.floatValue,
                                    );
                                  }}
                                />
                              )}
                            </Field>
                            <span className="form-text text-muted">
                              Valor mínimo a ser financiado
                            </span>
                            <ErrorMessage
                              name="valorMinimoFinanciamento"
                              component="small"
                            />
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Valor máximo</label>
                            <Field name="valorMaximoFinanciamento">
                              {({ field }) => (
                                <InputNumber
                                  {...field}
                                  onChange={(f) => f}
                                  className="form-control form-control-solid form-control-lg"
                                  placeholder="Digite a TAC"
                                  prefix="R$ "
                                  allowNegative={false}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  maxLength={10}
                                  value={values.valorMaximoFinanciamento}
                                  onValueChange={(values) => {
                                    setFieldValue(
                                      'valorMaximoFinanciamento',
                                      values.floatValue,
                                    );
                                  }}
                                />
                              )}
                            </Field>

                            <span className="form-text text-muted">
                              Valor máximo a ser financiado
                            </span>
                            <ErrorMessage
                              name="valorMaximoFinanciamento"
                              component="small"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>TAC "%"</label>

                            <Field name="tacPercentual">
                              {({ field }) => (
                                <InputNumber
                                  {...field}
                                  onChange={(f) => f}
                                  className="form-control form-control-solid form-control-lg"
                                  placeholder="Digite o TAC"
                                  allowNegative={false}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  maxLength={8}
                                  suffix=" %"
                                  value={values.tacPercentual}
                                  onValueChange={(values) => {
                                    setFieldValue(
                                      'tacPercentual',
                                      values.floatValue,
                                    );
                                  }}
                                />
                              )}
                            </Field>

                            <span className="form-text text-muted">
                              Taxa de abertura de crédito
                            </span>
                            <ErrorMessage
                              name="tacPercentual"
                              component="small"
                            />
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>TAC "R$"</label>
                            <Field name="tacValorReais">
                              {({ field }) => (
                                <InputNumber
                                  {...field}
                                  onChange={(f) => f}
                                  className="form-control form-control-solid form-control-lg"
                                  placeholder="Digite a TAC"
                                  prefix="R$ "
                                  allowNegative={false}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  maxLength={10}
                                  value={values.tacValorReais}
                                  onValueChange={(values) => {
                                    setFieldValue(
                                      'tacValorReais',
                                      values.floatValue,
                                    );
                                  }}
                                />
                              )}
                            </Field>

                            <span className="form-text text-muted">
                              Taxa em reais de de abertura de crédito
                            </span>
                            <ErrorMessage
                              name="tacValorReais"
                              component="small"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Plano</label>
                            <Select
                              name="planoComOuSemEntrada"
                              value={planos.find(
                                (plano) =>
                                  plano.value === values.planoComOuSemEntrada,
                              )}
                              onChange={(e) =>
                                setFieldValue('planoComOuSemEntrada', e.value)
                              }
                              placeholder="Selecione o plano"
                              options={planos}
                            />

                            <span className="form-text text-muted">
                              Disponibilizar planos de parcelamento com ou sem
                              entrada.
                            </span>
                            <ErrorMessage
                              name="planoComOuSemEntrada"
                              component="small"
                            />
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Taxa de ADM</label>

                            <Field name="taxaAdmin">
                              {({ field }) => (
                                <InputNumber
                                  {...field}
                                  onChange={(f) => f}
                                  className="form-control form-control-solid form-control-lg"
                                  placeholder="Digite a taxa de ADM"
                                  allowNegative={false}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  maxLength={8}
                                  suffix=" %"
                                  value={values.taxaAdmin}
                                  onValueChange={(values) => {
                                    setFieldValue(
                                      'taxaAdmin',
                                      values.floatValue,
                                    );
                                  }}
                                />
                              )}
                            </Field>

                            <span className="form-text text-muted">
                              Taxa de ADM
                            </span>
                            <ErrorMessage name="taxaAdmin" component="small" />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Taxa de antecipação</label>

                            <Field name="taxaAntecipacao">
                              {({ field }) => (
                                <InputNumber
                                  {...field}
                                  onChange={(f) => f}
                                  className="form-control form-control-solid form-control-lg"
                                  placeholder="Digite a taxa de antecipação"
                                  allowNegative={false}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  maxLength={8}
                                  suffix=" %"
                                  value={values.taxaAntecipacao}
                                  onValueChange={(values) => {
                                    setFieldValue(
                                      'taxaAntecipacao',
                                      values.floatValue,
                                    );
                                  }}
                                />
                              )}
                            </Field>

                            <span className="form-text text-muted">
                              Taxa de antecipação
                            </span>
                            <ErrorMessage
                              name="taxaAntecipacao"
                              component="small"
                            />
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="form-group">
                            <label>Mensalidade</label>
                            <Field name="valorMensalidade">
                              {({ field }) => (
                                <InputNumber
                                  {...field}
                                  onChange={(f) => f}
                                  className="form-control form-control-solid form-control-lg"
                                  placeholder="Digite a mensalidade"
                                  prefix="R$ "
                                  allowNegative={false}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  maxLength={10}
                                  value={values.valorMensalidade}
                                  onValueChange={(values) => {
                                    setFieldValue(
                                      'valorMensalidade',
                                      values.floatValue,
                                    );
                                  }}
                                />
                              )}
                            </Field>

                            <span className="form-text text-muted">
                              Mensalidade pelo uso da plataforma
                            </span>
                            <ErrorMessage
                              name="valorMensalidade"
                              component="small"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-end border-top mt-5 pt-10">
                        <button
                          type="submit"
                          className="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
                        >
                          Salvar
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Card>
      </Overlay>
    </Container>
  );
}
