/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react';

import { Link } from 'react-router-dom';

import Card from '../../components/elements/Card';
import Overlay from '../../components/Overlay';

import { ParceiroContext } from '../../context/ParceiroContext';

import { genericPhoneFormatter } from '../../helpers/Utils';

import './styles.scss';

export default function ParceirosList() {
  const { parceiros, loading } = useContext(ParceiroContext);

  const RenderThead = () => (
    <tr className="text-uppercase">
      <th style={{ minWidth: 200 }}>Nome Fantasia</th>
      <th style={{ minWidth: 150 }}>CNPJ</th>
      <th>Liberado Homologação</th>
      <th>Liberado Produção</th>
      <th className="pr-0 text-right" style={{ minWidth: 160 }}>
        Detalhes
      </th>
    </tr>
  );

  const RenderTrs = () => {
    return parceiros?.map(
      ({
        parceiroId,
        nomeFantasia,
        liberadoParaHomologacao,
        liberadoParaProducao,
        celular,
        cnpj,
      }) => (
        <tr key={parceiroId}>
          <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
              {nomeFantasia}
            </span>
            <span className="text-muted font-weight-bold">
              {genericPhoneFormatter(celular)}
            </span>
          </td>
          <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
              {cnpj}
            </span>
          </td>
          <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
              {liberadoParaHomologacao ? 'Sim' : 'Não'}
            </span>
          </td>
          <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
              {liberadoParaProducao ? 'Sim' : 'Não'}
            </span>
          </td>

          <td className="pr-0 text-right">
            <Link
              to={`parceiro/${parceiroId}`}
              className="btn btn-icon btn-outline-success btn-circle btn-sm mr-2"
            >
              <i className="fas fa-search-plus"></i>
            </Link>
          </td>
        </tr>
      ),
    );
  };

  return (
    <Overlay active={loading}>
      <Card>
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Parceiros
            </span>
            <span className="text-dark mt-3 font-weight-bold font-size-sm">
              Você está visualizando <b>{parceiros?.length} parceiros</b>
            </span>
          </h3>
        </div>
        <div className="list-propostas">
          <div className="card-body py-0">
            <div className="table-responsive">
              <table
                className="table table-head-custom table-vertical-center"
                id="kt_advance_table_widget_2"
              >
                <thead>
                  <RenderThead />
                </thead>
                <tbody>
                  <RenderTrs />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Card>
    </Overlay>
  );
}
