import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import Card from '../../components/elements/Card';
import Overlay from '../../components/Overlay';

import history from '../../history';

import { getAeroportos } from '../../webReferences/aeroportosAPI';

import { formatSearch } from '../../helpers/Utils';

import { ReactComponent as PlaneIcon } from '../../assets/svg/plane.svg';

import './styles.scss';

export default function AeroportosList() {
  const [loading, setLoading] = useState(false);
  const [aeroportos, setAeroportos] = useState([]);
  const [apiList, setApiList] = useState([]);

  const adicionarAeroporto = () => history.push('/aeroporto');

  useEffect(() => {
    async function loadAeroportos() {
      setLoading(true);
      const ret = await getAeroportos();

      setAeroportos(ret.data || []);
      setApiList(ret.data || []);
      setLoading(false);
    }

    loadAeroportos();
  }, []);

  const RenderThead = () => (
    <tr className="text-uppercase">
      <th>Sigla</th>
      <th style={{ minWidth: 200 }}>Descrição</th>
      <th style={{ minWidth: 150 }}>Cidade</th>
      <th>Estado</th>
      <th>País</th>
      <th className="pr-0 text-right" style={{ minWidth: 160 }}>
        Detalhes
      </th>
    </tr>
  );

  const RenderTrs = () => {
    return aeroportos?.map(
      ({ aeroportoId, sigla, descricao, cidade, estado, pais }) => (
        <tr key={aeroportoId}>
          <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
              {sigla}
            </span>
          </td>
          <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
              {descricao}
            </span>
          </td>
          <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
              {cidade}
            </span>
          </td>
          <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
              {estado}
            </span>
          </td>
          <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
              {pais}
            </span>
          </td>

          <td className="pr-0 text-right">
            <Link
              to={`aeroporto/${aeroportoId}`}
              className="btn btn-icon btn-outline-success btn-circle btn-sm mr-2"
            >
              <i className="fas fa-search-plus"></i>
            </Link>
          </td>
        </tr>
      ),
    );
  };

  const handleSearch = (search) => {
    const find = apiList.filter(
      ({ sigla, descricao, cidade, estado, pais }) =>
        formatSearch(sigla).includes(formatSearch(search)) ||
        formatSearch(descricao).includes(formatSearch(search)) ||
        formatSearch(cidade).includes(formatSearch(search)) ||
        formatSearch(estado).includes(formatSearch(search)) ||
        formatSearch(pais).includes(formatSearch(search)),
    );

    setAeroportos(find);
  };

  return (
    <Overlay active={loading}>
      <Card>
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Aeroportos
            </span>
            <span className="text-dark mt-3 font-weight-bold font-size-sm">
              Você está visualizando <b>{aeroportos?.length} aeroportos</b>
            </span>
          </h3>
          <div className="d-flex col-lg-5">
            <input
              type="text"
              placeholder="Pesquisar na tabela"
              className="form-control"
              onChange={(e) => handleSearch(e.target.value)}
            />
            <button
              type="button"
              className="btn btn-success form-control col-lg-4 ml-2"
              onClick={() => adicionarAeroporto()}
            >
              <PlaneIcon /> Adicionar
            </button>
          </div>
        </div>
        <div className="list-propostas">
          <div className="card-body py-0">
            <div className="table-responsive">
              <table
                className="table table-head-custom table-vertical-center"
                id="kt_advance_table_widget_2"
              >
                <thead>
                  <RenderThead />
                </thead>
                <tbody>
                  <RenderTrs />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Card>
    </Overlay>
  );
}
