import React, { useEffect, useState } from 'react';
import { Formik, ErrorMessage, Field, Form } from 'formik';

import {
  getConfiguracoes,
  atualizarConfiguracoes,
} from '../../webReferences/configsGeraisAPI';

import InputNumber from '../../components/InputNumber';

import validationSchema from './validationSchema';
import initialValues from './initialValues';

import Container from '../../components/elements/Container';
import Card from '../../components/elements/Card';
import Overlay from '../../components/Overlay';
import Modal from '../../components/Modal';

export default function ConfiguracoesParceiro() {
  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadConfiguracoes() {
      setLoading(true);
      const ret = await getConfiguracoes();

      setFormData((formData) => ({
        ...formData,
        ...(ret.data || initialValues),
      }));
      setLoading(false);
    }

    loadConfiguracoes();
  }, []);

  return (
    <Container>
      <Overlay active={loading} marginTop={300}>
        <Card>
          <div className="card-body p-0">
            <div className="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
              <div className="col-xl-12 col-xxl-7">
                <Formik
                  initialValues={formData}
                  validationSchema={validationSchema}
                  validateOnBlur={true}
                  enableReinitialize={true}
                  onSubmit={async (data) => {
                    setLoading(true);

                    const {
                      configuracoesGeraisId,
                      quantidadeTentativaSMS,
                      quantidadeTentativaEmail,
                      tempoCronometroSMS,
                      tempoCronometroEmail,
                      tempoSessaoUsuarioCheckout,
                      tempoSessaoAreaLogadaCliente,
                      tempoSessaoAreaLogadaParceiro,
                      tempoSessaoAreaLogadaAdmin,
                    } = data;

                    const payload = {
                      configuracoesGeraisId,
                      quantidadeTentativaSMS,
                      quantidadeTentativaEmail,
                      tempoCronometroSMS,
                      tempoCronometroEmail,
                      tempoSessaoUsuarioCheckout,
                      tempoSessaoAreaLogadaCliente,
                      tempoSessaoAreaLogadaParceiro,
                      tempoSessaoAreaLogadaAdmin,
                    };

                    const response = await atualizarConfiguracoes(
                      payload,
                    ).catch(() => setLoading(false));

                    if (response?.sucesso) {
                      setLoading(false);

                      Modal({
                        text: 'Configurações atualizadas com sucesso!',
                        icon: 'success',
                      });
                    }
                  }}
                >
                  {({ values, setFieldValue }) =>
                    values && (
                      <Form>
                        <h2 className="mb-10 font-weight-bolder">
                          Configurações gerais
                        </h2>
                        <div className="form-group">
                          <h4 className="mb-10 font-weight-bold text-primary">
                            <i className="far fa-edit text-primary"></i>{' '}
                            Parâmetros utilizados para a quantidade de
                            tentativas de envio:
                          </h4>
                        </div>

                        <div className="row">
                          <div className="col-xl-6">
                            <div className="form-group">
                              <label>SMS</label>

                              <Field name="quantidadeTentativaSMS">
                                {({ field }) => (
                                  <InputNumber
                                    {...field}
                                    onChange={(f) => f}
                                    className="form-control form-control-solid form-control-lg"
                                    placeholder="Digite o parâmetro do SMS"
                                    allowNegative={false}
                                    maxLength={2}
                                    value={values?.quantidadeTentativaSMS}
                                    onValueChange={(values) => {
                                      setFieldValue(
                                        'quantidadeTentativaSMS',
                                        values.floatValue,
                                      );
                                    }}
                                  />
                                )}
                              </Field>

                              <span className="form-text text-muted">
                                Quantidade de tentativa de envio de SMS
                              </span>
                              <ErrorMessage
                                name="quantidadeTentativaSMS"
                                component="small"
                              />
                            </div>
                          </div>
                          <div className="col-xl-6">
                            <div className="form-group">
                              <label>E-mail</label>

                              <Field name="quantidadeTentativaEmail">
                                {({ field }) => (
                                  <InputNumber
                                    {...field}
                                    onChange={(f) => f}
                                    className="form-control form-control-solid form-control-lg"
                                    placeholder="Digite o parâmetro do E-mail"
                                    allowNegative={false}
                                    maxLength={2}
                                    value={values?.quantidadeTentativaEmail}
                                    onValueChange={(values) => {
                                      setFieldValue(
                                        'quantidadeTentativaEmail',
                                        values.floatValue,
                                      );
                                    }}
                                  />
                                )}
                              </Field>

                              <span className="form-text text-muted">
                                Quantidade de tentativa de envio de e-mail
                              </span>
                              <ErrorMessage
                                name="quantidadeTentativaEmail"
                                component="small"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <h4 className="mb-10 font-weight-bold text-primary">
                            <i className="far fa-edit text-primary"></i>{' '}
                            Parâmetros utilizados para o cronômetro de envio do
                            tokens:
                          </h4>
                        </div>

                        <div className="row">
                          <div className="col-xl-6">
                            <div className="form-group">
                              <label>SMS</label>

                              <Field name="tempoCronometroSMS">
                                {({ field }) => (
                                  <InputNumber
                                    {...field}
                                    onChange={(f) => f}
                                    className="form-control form-control-solid form-control-lg"
                                    placeholder="Digite o parâmetro do SMS"
                                    allowNegative={false}
                                    maxLength={2}
                                    value={values?.tempoCronometroSMS}
                                    onValueChange={(values) => {
                                      setFieldValue(
                                        'tempoCronometroSMS',
                                        values.floatValue,
                                      );
                                    }}
                                  />
                                )}
                              </Field>

                              <span className="form-text text-muted">
                                Tempo de cromômetro de envio de SMS
                              </span>
                              <ErrorMessage
                                name="tempoCronometroSMS"
                                component="small"
                              />
                            </div>
                          </div>
                          <div className="col-xl-6">
                            <div className="form-group">
                              <label>E-mail</label>

                              <Field name="tempoCronometroEmail">
                                {({ field }) => (
                                  <InputNumber
                                    {...field}
                                    onChange={(f) => f}
                                    className="form-control form-control-solid form-control-lg"
                                    placeholder="Digite o parâmetro do E-mail"
                                    allowNegative={false}
                                    maxLength={2}
                                    value={values?.tempoCronometroEmail}
                                    onValueChange={(values) => {
                                      setFieldValue(
                                        'tempoCronometroEmail',
                                        values.floatValue,
                                      );
                                    }}
                                  />
                                )}
                              </Field>

                              <span className="form-text text-muted">
                                Tempo de cromômetro de envio de e-mail
                              </span>
                              <ErrorMessage
                                name="tempoCronometroEmail"
                                component="small"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <h4 className="mb-10 font-weight-bold text-primary">
                            <i className="far fa-edit text-primary"></i>{' '}
                            Parâmetros utilizados para o tempo de sessão do
                            usuário:
                          </h4>
                        </div>

                        <div className="row">
                          <div className="col-xl-6">
                            <div className="form-group">
                              <label>Checkout</label>

                              <Field name="tempoSessaoUsuarioCheckout">
                                {({ field }) => (
                                  <InputNumber
                                    {...field}
                                    onChange={(f) => f}
                                    className="form-control form-control-solid form-control-lg"
                                    placeholder="Digite o parâmetro do checkout"
                                    allowNegative={false}
                                    maxLength={2}
                                    value={values?.tempoSessaoUsuarioCheckout}
                                    onValueChange={(values) => {
                                      setFieldValue(
                                        'tempoSessaoUsuarioCheckout',
                                        values.floatValue,
                                      );
                                    }}
                                  />
                                )}
                              </Field>

                              <span className="form-text text-muted">
                                Tempo de sessão do checkout
                              </span>
                              <ErrorMessage
                                name="tempoSessaoUsuarioCheckout"
                                component="small"
                              />
                            </div>
                          </div>
                          <div className="col-xl-6">
                            <div className="form-group">
                              <label>Área logada cliente</label>

                              <Field name="tempoSessaoAreaLogadaCliente">
                                {({ field }) => (
                                  <InputNumber
                                    {...field}
                                    onChange={(f) => f}
                                    className="form-control form-control-solid form-control-lg"
                                    placeholder="Digite o parâmetro da área cliente"
                                    allowNegative={false}
                                    maxLength={2}
                                    value={values?.tempoSessaoAreaLogadaCliente}
                                    onValueChange={(values) => {
                                      setFieldValue(
                                        'tempoSessaoAreaLogadaCliente',
                                        values.floatValue,
                                      );
                                    }}
                                  />
                                )}
                              </Field>

                              <span className="form-text text-muted">
                                Tempo de sessão da área do cliente
                              </span>
                              <ErrorMessage
                                name="tempoSessaoAreaLogadaCliente"
                                component="small"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xl-6">
                            <div className="form-group">
                              <label>Área logada parceiro</label>

                              <Field name="tempoSessaoAreaLogadaParceiro">
                                {({ field }) => (
                                  <InputNumber
                                    {...field}
                                    onChange={(f) => f}
                                    className="form-control form-control-solid form-control-lg"
                                    placeholder="Digite o parâmetro da área parceiro"
                                    allowNegative={false}
                                    maxLength={2}
                                    value={
                                      values?.tempoSessaoAreaLogadaParceiro
                                    }
                                    onValueChange={(values) => {
                                      setFieldValue(
                                        'tempoSessaoAreaLogadaParceiro',
                                        values.floatValue,
                                      );
                                    }}
                                  />
                                )}
                              </Field>

                              <span className="form-text text-muted">
                                Tempo de sessão da área do parceiro
                              </span>
                              <ErrorMessage
                                name="tempoSessaoAreaLogadaParceiro"
                                component="small"
                              />
                            </div>
                          </div>
                          <div className="col-xl-6">
                            <div className="form-group">
                              <label>Área logada admin</label>

                              <Field name="tempoSessaoAreaLogadaAdmin">
                                {({ field }) => (
                                  <InputNumber
                                    {...field}
                                    onChange={(f) => f}
                                    className="form-control form-control-solid form-control-lg"
                                    placeholder="Digite o parâmetro da área admin"
                                    allowNegative={false}
                                    maxLength={2}
                                    value={values?.tempoSessaoAreaLogadaAdmin}
                                    onValueChange={(values) => {
                                      setFieldValue(
                                        'tempoSessaoAreaLogadaAdmin',
                                        values.floatValue,
                                      );
                                    }}
                                  />
                                )}
                              </Field>

                              <span className="form-text text-muted">
                                Tempo de sessão da área do admin
                              </span>
                              <ErrorMessage
                                name="tempoSessaoAreaLogadaAdmin"
                                component="small"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-end border-top mt-5 pt-10">
                          <button
                            type="submit"
                            className="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
                          >
                            Salvar
                          </button>
                        </div>
                      </Form>
                    )
                  }
                </Formik>
              </div>
            </div>
          </div>
        </Card>
      </Overlay>
    </Container>
  );
}
