import React from 'react';
import NumberField from 'react-number-format';

function InputNumber({ ...props }) {
  return (
    <NumberField
      thousandSeparator="."
      decimalSeparator=","
      displayType="input"
      {...props}
    />
  );
}

export default InputNumber;
