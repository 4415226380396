import React, { createContext, useState, useEffect, useCallback } from 'react';
import { listLogs } from '../webReferences/logAPI';

export const LogContext = createContext({
  loading: false,
  logs: [],
});

export default function LogProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState([]);

  const loadLogs = useCallback(async () => {
    setLoading(true);
    const ret = await listLogs().catch(() => setLoading(false));

    setLogs(ret?.data || []);
    setLoading(false);
  }, []);

  useEffect(() => {
    loadLogs();
  }, [loadLogs]);

  return (
    <LogContext.Provider value={{ loading, logs }}>
      {children}
    </LogContext.Provider>
  );
}
