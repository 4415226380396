import React, { useEffect, useState } from 'react';
import { Formik, ErrorMessage, Field, Form } from 'formik';

import { useParams } from 'react-router-dom';

import {
  getAeroportoById,
  atualizarAeroporto,
  cadastrarAeroporto,
} from '../../webReferences/aeroportosAPI';

import validationSchema from './validationSchema';
import initialValues from './initialValues';

import Container from '../../components/elements/Container';
import Card from '../../components/elements/Card';
import Overlay from '../../components/Overlay';
import Modal from '../../components/Modal';

export default function Aeroporto() {
  const params = useParams();
  const aeroportoId = Number(params?.aeroportoId);

  const [formData, setFormData] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadAeroporto() {
      setLoading(true);
      const ret = await getAeroportoById(aeroportoId);

      setFormData((formData) => ({
        ...formData,
        ...(ret.data || initialValues),
      }));
      setLoading(false);
    }

    if (aeroportoId) {
      loadAeroporto();
    }
  }, [aeroportoId]);

  return (
    <Container>
      <Overlay active={loading} marginTop={300}>
        <Card>
          <div className="card-body p-0">
            <div className="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
              <div className="col-xl-12 col-xxl-7">
                <Formik
                  initialValues={formData}
                  validationSchema={validationSchema}
                  validateOnBlur={true}
                  enableReinitialize={true}
                  onSubmit={async (data) => {
                    setLoading(true);

                    if (!aeroportoId) {
                      const response = await cadastrarAeroporto(data).catch(
                        () => setLoading(false),
                      );

                      if (response?.sucesso) {
                        setLoading(false);

                        Modal({
                          text: 'Aeroporto cadastrado com sucesso!',
                          icon: 'success',
                        });
                      }
                    } else {
                      const response = await atualizarAeroporto(data).catch(
                        () => setLoading(false),
                      );

                      if (response?.sucesso) {
                        setLoading(false);

                        Modal({
                          text: 'Aeroporto atualizado com sucesso!',
                          icon: 'success',
                        });
                      }
                    }
                  }}
                >
                  <Form>
                    <h2 className="mb-10 font-weight-bolder">
                      {aeroportoId ? 'Editar aeroporto' : 'Cadastrar aeroporto'}
                    </h2>
                    <div className="form-group">
                      <h4 className="mb-10 font-weight-bold text-primary">
                        <i className="far fa-edit text-primary"></i> Dados do
                        aeroporto
                      </h4>
                    </div>

                    <div className="form-group">
                      <label>Sigla</label>

                      <Field
                        name="sigla"
                        className="form-control form-control-solid form-control-lg"
                        placeholder="Digite a sigla do aeroporto"
                      />

                      <span className="form-text text-muted">
                        Sigla do aeroporto
                      </span>
                      <ErrorMessage name="sigla" component="small" />
                    </div>

                    <div className="row">
                      <div className="col-xl-6">
                        <div className="form-group">
                          <label>Descrição</label>

                          <Field
                            name="descricao"
                            className="form-control form-control-solid form-control-lg"
                            placeholder="Digite a descrição do aeroporto"
                          />

                          <span className="form-text text-muted">
                            Descrição do aeroporto
                          </span>
                          <ErrorMessage name="descricao" component="small" />
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="form-group">
                          <label>Cidade</label>

                          <Field
                            name="cidade"
                            className="form-control form-control-solid form-control-lg"
                            placeholder="Digite a cidade do aeroporto"
                          />

                          <span className="form-text text-muted">
                            Cidade do aeroporto
                          </span>
                          <ErrorMessage name="cidade" component="small" />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xl-6">
                        <div className="form-group">
                          <label>Estado</label>

                          <Field
                            name="estado"
                            className="form-control form-control-solid form-control-lg"
                            placeholder="Digite o estado do aeroporto"
                          />

                          <span className="form-text text-muted">
                            Estado do aeroporto
                          </span>
                          <ErrorMessage name="estado" component="small" />
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="form-group">
                          <label>País</label>

                          <Field
                            name="pais"
                            className="form-control form-control-solid form-control-lg"
                            placeholder="Digite o país do aeroporto"
                          />

                          <span className="form-text text-muted">
                            País do aeroporto
                          </span>
                          <ErrorMessage name="pais" component="small" />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-end border-top mt-5 pt-10">
                      <button
                        type="submit"
                        className="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
                      >
                        {aeroportoId ? 'Salvar' : 'Cadastrar'}
                      </button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </Card>
      </Overlay>
    </Container>
  );
}
