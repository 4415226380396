import { object, string, number } from 'yup';

const validationSchema = object().shape({
  nomeFantasia: string().required('O nome fantasia é obrigatório').nullable(),
  razaoSocial: string().required('A razão social é obrigatória').nullable(),
  celular: string().required('O celular é obrigatório').nullable(),

  codigoBanco: string().required('O código do banco é obrigatório').nullable(),
  tipoConta: string().required('O tipo de conta é obrigatório').nullable(),
  agencia: string().required('A agência é obrigatória').nullable(),
  conta: string().required('A conta obrigatória').nullable(),
  contaDig: string().required('O dígito da conta é obrigatório').nullable(),
  endereco: object().shape({
    cep: string()
      .matches(/\d{5}-\d{3}/, 'CEP inválido')
      .required('O cep é obrigatório')
      .nullable(),
    bairro: string().required('O bairro é obrigatório').nullable(),
    logradouro: string().required('O logradouro é obrigatório').nullable(),
    estado: string().required('O estado é obrigatório').nullable(),
    cidade: string().required('A cidade é obrigatória').nullable(),
    numero: number().required('O número é obrigatório').nullable(),
  }),
});

export default validationSchema;
