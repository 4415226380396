const initialValues = {
  taxaCliente: null,
  quantidadeParcelamentoMinimo: null,
  quantidadeParcelamentoMaximo: null,
  valorMinimoFinanciamento: null,
  valorMaximoFinanciamento: null,
  tacPercentual: null,
  tacValorReais: null,
  planoComOuSemEntrada: null,
  taxaAdmin: null,
  taxaAntecipacao: null,
  valorMensalidade: null,
};

export default initialValues;
