import api from './api';

export const getConfiguracoes = async (parceiroId) => {
  const { data } = await api.get(
    `/parceiro/GetConfiguracoes?parceiroId=${parceiroId}`,
  );
  return data;
};

export const atualizarConfiguracoes = async (req) => {
  const { data } = await api.post('/parceiro/AtualizarConfiguracoes', req);
  return data;
};
