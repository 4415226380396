import React from 'react';

import Container from '../../components/elements/Container';

import BancosList from './BancosList';

export default function Bancos() {
  return (
    <Container>
      <BancosList />
    </Container>
  );
}
