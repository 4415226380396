import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Login from '../pages/Login';
import Home from '../pages/Home';
import Perfil from '../pages/Perfil';
import Parceiros from '../pages/Parceiros';
import Parceiro from '../pages/Parceiro';
import ConfiguracoesParceiro from '../pages/ConfiguracoesParceiro';
import Configuracoes from '../pages/Configuracoes';
import Aeroportos from '../pages/Aeroportos';
import Aeroporto from '../pages/Aeroporto';
import Bancos from '../pages/Bancos';
import Banco from '../pages/Banco';
import Propostas from '../pages/Propostas';
import Proposta from '../pages/Proposta';
import Logs from '../pages/Logs';

import PrivateRoute from './privateRoute';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/login" component={Login} />
      <PrivateRoute exact path="/home" component={Home} />
      <PrivateRoute exact path="/perfil" component={Perfil} />
      <PrivateRoute exact path="/parceiros" component={Parceiros} />
      <PrivateRoute exact path="/parceiro/:parceiroId" component={Parceiro} />
      <PrivateRoute
        exact
        path="/configuracoes-parceiro/:parceiroId"
        component={ConfiguracoesParceiro}
      />
      <PrivateRoute exact path="/configuracoes" component={Configuracoes} />
      <PrivateRoute exact path="/aeroportos" component={Aeroportos} />
      <PrivateRoute path="/aeroporto/:aeroportoId?" component={Aeroporto} />
      <PrivateRoute exact path="/bancos" component={Bancos} />
      <PrivateRoute path="/banco/:bancoId?" component={Banco} />
      <PrivateRoute path="/propostas" component={Propostas} />
      <PrivateRoute exact path="/proposta/:propostaId" component={Proposta} />
      <PrivateRoute path="/logs" component={Logs} />
    </Switch>
  );
}
