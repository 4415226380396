import api from './api';

export const getBancos = async () => {
  const { data } = await api.get('/banco/ListBancos');
  return data;
};

export const getBancoById = async (bancoId) => {
  const { data } = await api.get('/banco/bancoGetById', {
    params: {
      bancoId,
    },
  });
  return data;
};

export const cadastrarBanco = async (req) => {
  const { data } = await api.post('/banco/CadastrarBanco', req);
  return data;
};

export const atualizarBanco = async (req) => {
  const { data } = await api.post('/banco/AtualizarBanco', req);
  return data;
};
