import { object, string, number, ref } from 'yup';

const validationSchema = object().shape({
  nomeUsuario: string().required('O nome é obrigatório').nullable(),
  email: string()
    .email('E-mail inválido')
    .required('O email é obrigatório')
    .nullable(),
  celular: string().required('O celular é obrigatório').nullable(),
  endereco: object().shape({
    cep: string()
      .matches(/\d{5}-\d{3}/, 'CEP inválido')
      .required('O cep é obrigatório')
      .nullable(),
    bairro: string().required('O bairro é obrigatório').nullable(),
    logradouro: string().required('O logradouro é obrigatório').nullable(),
    estado: string().required('O estado é obrigatório').nullable(),
    cidade: string().required('A cidade é obrigatória').nullable(),
    numero: number().required('O número é obrigatório').nullable(),
  }),
  senha: string().matches(
    /^(?=.*\d)(?=.*[A-Z])([^\s]){8,}$/,
    'Importante: A senha deve conter pelo menos 1 letra maiúscula, 1 número e 8 caracteres.',
  ),
  confirmarSenha: string()
    .when('senha', {
      is: (val) => val,
      then: string().required('É obrigatório redigir a senha'),
    })
    .oneOf([ref('senha'), null], 'As senhas não coincidem'),
});

export default validationSchema;
