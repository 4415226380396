import api from './api';

export const getParceiros = async () => {
  const { data } = await api.get('/parceiro/ListParceiros');

  return data;
};

export const getParceiro = async (parceiroId) => {
  const { data } = await api.get(
    `/parceiro/GetParceiro?parceiroId=${parceiroId}`,
  );

  return data;
};

export const atualizarParceiro = async (req) => {
  const { data } = await api.post('/parceiro/AtualizarParceiro', req);

  return data;
};

export const liberarHomologacao = async (parceiroId) => {
  const { data } = await api.post('/parceiro/LiberarParaHomologacao', {
    parceiroId,
  });

  return data;
};

export const liberarProducao = async (parceiroId) => {
  const { data } = await api.post('/parceiro/LiberarParaProducao', {
    parceiroId,
  });

  return data;
};

export const recusar = async (parceiroId) => {
  const { data } = await api.post('/parceiro/Recusar', {
    parceiroId,
  });

  return data;
};
