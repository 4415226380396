import api from './api';

export const getPropostas = async (req) => {
  const { data } = await api.post('/proposta/ListPropostasAdmin', req);

  return data;
};

export const getProposta = async (propostaId) => {
  try {
    const { data } = await api.get(
      `/proposta/GetProposta?propostaId=${propostaId}`,
    );
    return data.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
