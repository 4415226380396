/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from 'react';
import { format } from 'date-fns';

import { Link } from 'react-router-dom';

import Card from '../../components/elements/Card';
import Overlay from '../../components/Overlay';

import { PropostaContext } from '../../context/PropostaContext';

import { genericPhoneFormatter } from '../../helpers/Utils';

import ModalFilter from './ModalFilter';

import { ReactComponent as ArrowDownIcon } from '../../assets/svg/arrow-down.svg';

export default function PropostasList() {
  const { propostas, loading } = useContext(PropostaContext);

  const getStatus = (status) => {
    switch (status) {
      case 'Aguardando crédito':
      case 'Em andamento':
      case 'Aguardando assinatura':
        return 'primary';
      case 'Pendente':
        return 'warning';
      case 'Assinado':
      case 'Crédito efetuado':
        return 'success';
      case 'Recusado':
      case 'Cancelado':
        return 'danger';
      default:
    }
  };

  const RenderThead = () => (
    <tr className="text-uppercase">
      <th className="pl-0" style={{ width: 40 }}>
        <label className="checkbox checkbox-lg checkbox-inline mr-2">
          <input type="checkbox" value="1" />
          <span></span>
        </label>
      </th>
      <th className="pl-0" style={{ minWidth: 100 }}>
        ID
      </th>
      <th>Parceiro</th>
      <th style={{ minWidth: 300 }}>Dados</th>
      <th style={{ minWidth: 150 }}>
        <span className="text-primary">Data &amp; Prazo</span>
        <span className="svg-icon svg-icon-sm svg-icon-primary">
          <ArrowDownIcon />
        </span>
      </th>
      <th style={{ minWidth: 130 }}>Status</th>
      <th className="pr-0 text-right" style={{ minWidth: 160 }}>
        Ação
      </th>
    </tr>
  );

  const RenderTrs = () => {
    return propostas?.map(
      ({
        propostaId,
        codigoProposta,
        nomeFantasiaParceiro,
        nomeCliente,
        celularCliente,
        dataProposta,
        quantidadeParcelas,
        statusProposta,
      }) => (
        <tr key={codigoProposta}>
          <td className="pl-0 py-6">
            <label className="checkbox checkbox-lg checkbox-inline">
              <input type="checkbox" value="1" />
              <span></span>
            </label>
          </td>
          <td className="text-dark-75 font-weight-bolder pl-0">
            {codigoProposta}
          </td>
          <td className="text-dark-75 font-weight-bolder pl-0">
            {nomeFantasiaParceiro}
          </td>
          <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
              {nomeCliente}
            </span>
            <span className="text-muted font-weight-bold">
              {genericPhoneFormatter(celularCliente)}
            </span>
          </td>
          <td>
            <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
              {format(new Date(dataProposta), 'dd/MM/yyyy')}
            </span>
            <span className="text-muted font-weight-bold">
              {quantidadeParcelas} meses
            </span>
          </td>
          <td>
          <span
              className={`label label-lg label-light-${getStatus(
                statusProposta,
              )} label-inline`}
            >
              {statusProposta}
            </span>
          </td>
          <td className="pr-0 text-right">
            <Link
              to={`proposta/${propostaId}`}
              className="btn btn-icon btn-outline-success btn-circle btn-sm mr-2"
            >
              <i className="fas fa-search-plus"></i>
            </Link>
            {/* <a
              href="pagamentos.html"
              className="btn btn-icon btn-outline-success btn-circle btn-sm mr-2"
            >
              <i className="fas fa-file-invoice"></i>
            </a> */}
          </td>
        </tr>
      ),
    );
  };

  return (
    <Overlay active={loading}>
      <Card>
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Propostas de Clientes
            </span>
            <span className="text-dark mt-3 font-weight-bold font-size-sm">
              Você está visualizando <b>{propostas?.length} propostas</b>
            </span>
          </h3>
          <div className="card-toolbar">
            <button
              type="button"
              className="btn btn-success"
              data-toggle="modal"
              data-target="#modalFilter"
            >
              Filtrar Propostas
            </button>

            <ModalFilter />
          </div>
        </div>
        <div className="list-propostas">
          <div className="card-body py-0">
            <div className="table-responsive">
              <table
                className="table table-head-custom table-vertical-center"
                id="kt_advance_table_widget_2"
              >
                <thead>
                  <RenderThead />
                </thead>
                <tbody>
                  <RenderTrs />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Card>
    </Overlay>
  );
}
