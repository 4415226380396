import React, { useEffect, useState } from 'react';
import { Formik, ErrorMessage, Field, Form } from 'formik';

import { useParams } from 'react-router-dom';

import {
  getBancoById,
  atualizarBanco,
  cadastrarBanco,
} from '../../webReferences/bancoAPI';

import validationSchema from './validationSchema';
import initialValues from './initialValues';

import Container from '../../components/elements/Container';
import Card from '../../components/elements/Card';
import Overlay from '../../components/Overlay';
import Modal from '../../components/Modal';

export default function Banco() {
  const params = useParams();
  const bancoId = Number(params?.bancoId);

  const [formData, setFormData] = useState(initialValues);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadBanco() {
      setLoading(true);
      const ret = await getBancoById(bancoId);

      setFormData((formData) => ({
        ...formData,
        ...(ret.data || initialValues),
      }));
      setLoading(false);
    }

    if (bancoId) {
      loadBanco();
    }
  }, [bancoId]);

  return (
    <Container>
      <Overlay active={loading} marginTop={300}>
        <Card>
          <div className="card-body p-0">
            <div className="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
              <div className="col-xl-12 col-xxl-7">
                <Formik
                  initialValues={formData}
                  validationSchema={validationSchema}
                  validateOnBlur={true}
                  enableReinitialize={true}
                  onSubmit={async (data) => {
                    setLoading(true);

                    if (!bancoId) {
                      const response = await cadastrarBanco(data).catch(() =>
                        setLoading(false),
                      );

                      if (response?.sucesso) {
                        setLoading(false);

                        Modal({
                          text: 'Banco cadastrado com sucesso!',
                          icon: 'success',
                        });
                      }
                    } else {
                      const response = await atualizarBanco(data).catch(() =>
                        setLoading(false),
                      );

                      if (response?.sucesso) {
                        setLoading(false);

                        Modal({
                          text: 'Banco atualizado com sucesso!',
                          icon: 'success',
                        });
                      }
                    }
                  }}
                >
                  <Form>
                    <h2 className="mb-10 font-weight-bolder">
                      {bancoId ? 'Editar banco' : 'Cadastrar banco'}
                    </h2>
                    <div className="form-group">
                      <h4 className="mb-10 font-weight-bold text-primary">
                        <i className="far fa-edit text-primary"></i> Dados do
                        banco
                      </h4>
                    </div>

                    <div className="row">
                      <div className="col-xl-6">
                        <div className="form-group">
                          <label>Código febraban</label>

                          <Field
                            name="codigoFebraBan"
                            className="form-control form-control-solid form-control-lg"
                            placeholder="Digite o código febraban"
                          />

                          <span className="form-text text-muted">
                            Código febraban
                          </span>
                          <ErrorMessage
                            name="codigoFebraBan"
                            component="small"
                          />
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="form-group">
                          <label>Descrição</label>

                          <Field
                            name="descricao"
                            className="form-control form-control-solid form-control-lg"
                            placeholder="Digite a descrição do banco"
                          />

                          <span className="form-text text-muted">
                            Descrição do banco
                          </span>
                          <ErrorMessage name="descricao" component="small" />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-end border-top mt-5 pt-10">
                      <button
                        type="submit"
                        className="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
                      >
                        {bancoId ? 'Salvar' : 'Cadastrar'}
                      </button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </Card>
      </Overlay>
    </Container>
  );
}
