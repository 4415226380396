import React, { useContext, useRef } from 'react';
import { Formik, ErrorMessage, Field, Form } from 'formik';
import InputMask from 'react-input-mask';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { PropostaContext } from '../../context/PropostaContext';
import validationSchema from './validationSchema';
import { addDays, add } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

registerLocale('pt-BR', ptBR);

export default function ModalFilter() {
  const { setFilters } = useContext(PropostaContext);
  const closeButton = useRef(null);

  const initialValues = {
    nomeCliente: '',
    nomeFantasiaParceiro: '',
    cpfCliente: '',
    email: '',
    celular: '',
    statusPropostaId: 0,
    dataInicio: '',
    dataFim: '',
  };

  return (
    <div
      className="modal fade"
      id="modalFilter"
      data-backdrop="static"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="staticBackdrop"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={true}
          onSubmit={async (data) => {
            setFilters((filters) => ({ ...filters, ...data }));
            closeButton.current.click();
          }}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Filtrar Propostas</h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i aria-hidden="true" className="ki ki-close"></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="card-body">
                    <div className="form-group row">
                      <label className="col-3 col-form-label">
                        Nome Fantasia Parceiro
                      </label>
                      <div className="col-9">
                        <Field
                          name="nomeFantasiaParceiro"
                          className="form-control"
                          placeholder="Digite o nome fantasia"
                        />
                        <ErrorMessage
                          name="nomeFantasiaParceiro"
                          component="small"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-3 col-form-label">Nome</label>
                      <div className="col-9">
                        <Field
                          name="nomeCliente"
                          className="form-control"
                          placeholder="Digite o nome"
                        />
                        <ErrorMessage name="nomeCliente" component="small" />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-3 col-form-label">CPF</label>
                      <div className="col-9">
                        <Field name="cpfCliente">
                          {({ field }) => (
                            <InputMask
                              {...field}
                              className="form-control"
                              mask="999.999.999-99"
                              placeholder="Digite o CPF"
                            />
                          )}
                        </Field>
                        <ErrorMessage name="cpfCliente" component="small" />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-3 col-form-label">E-mail</label>
                      <div className="col-9">
                        <Field
                          name="email"
                          className="form-control"
                          placeholder="Digite o e-mail"
                        />
                        <ErrorMessage name="email" component="small" />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-3 col-form-label">Celular</label>
                      <div className="col-9">
                        <Field name="celular">
                          {({ field }) => (
                            <InputMask
                              {...field}
                              className="form-control"
                              mask="(99) 99999-9999"
                              placeholder="Digite o celular"
                            />
                          )}
                        </Field>
                        <ErrorMessage name="celular" component="small" />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-3 col-form-label">Data</label>
                      <div className="col-9">
                        <div className="row">
                          <div className="col">
                            <div className="input-group">
                              <DatePicker
                                selected={values.dataInicio}
                                onChange={(newDate) => {
                                  setFieldValue('dataInicio', newDate);
                                  setFieldValue('dataFim', '');
                                }}
                                dateFormat="dd/MM/yyyy"
                                className="form-control"
                                selectsStart
                                startDate={values.dataInicio}
                                endDate={values.dataFim}
                                placeholderText="1º Data"
                                locale="pt-BR"
                              />
                            </div>
                            <ErrorMessage name="dataInicio" component="small" />
                          </div>
                          <div className="col">
                            <div className="input-group">
                              <DatePicker
                                selected={values.dataFim}
                                onChange={(newDate) => {
                                  const dataFim = add(new Date(newDate), {
                                    hours: 23,
                                    minutes: 59,
                                  });

                                  setFieldValue('dataFim', dataFim);
                                }}
                                dateFormat="dd/MM/yyyy"
                                className="form-control"
                                startDate={values.dataInicio}
                                selectsEnd
                                endDate={values.dataFim}
                                minDate={values.dataInicio}
                                maxDate={addDays(
                                  new Date(values.dataInicio),
                                  30,
                                )}
                                placeholderText="2º Data"
                                locale="pt-BR"
                                disabled={!values.dataInicio}
                              />
                            </div>
                            <ErrorMessage name="dataFim" component="small" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-light-muted font-weight-bold"
                    data-dismiss="modal"
                    ref={closeButton}
                  >
                    Fechar
                  </button>
                  <button
                    type="submit"
                    className="btn btn-success font-weight-bold"
                  >
                    Filtrar
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
