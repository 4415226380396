import { object, number } from 'yup';

const validationSchema = object().shape({
  quantidadeTentativaSMS: number()
    .required('A quantidade de tentativa do sms é obrigatória')
    .nullable(),
  quantidadeTentativaEmail: number()
    .required('A quantidade de tentativa do e-mail é obrigatória')
    .nullable(),
  tempoCronometroSMS: number()
    .required('O tempo de cronômetro do sms é obrigatório')
    .nullable(),
  tempoCronometroEmail: number()
    .required('O tempo de cronômetro do e-mail é obrigatório')
    .nullable(),
  tempoSessaoUsuarioCheckout: number()
    .required('O tempo de sessão do checkout é obrigatório')
    .nullable(),
  tempoSessaoAreaLogadaCliente: number()
    .required('O tempo de sessão da área logada cliente é obrigatório')
    .nullable(),
  tempoSessaoAreaLogadaParceiro: number()
    .required('O tempo de sessão da área parceiro cliente é obrigatório')
    .nullable(),
  tempoSessaoAreaLogadaAdmin: number()
    .required('O tempo de sessão da área logada admin é obrigatório')
    .nullable(),
});

export default validationSchema;
