import api from './api';

export const getConfiguracoes = async () => {
  const { data } = await api.get(
    '/configuracoesgerais/GetConfiguracoesGerais',
  );
  return data;
};

export const atualizarConfiguracoes = async (req) => {
  const { data } = await api.post('/configuracoesgerais/AtualizarConfiguracoesGerais', req);
  return data;
};
