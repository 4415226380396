import { object, string } from 'yup';

const validationSchema = object().shape({
  codigoFebraBan: string()
    .required('O código febraban é obrigatório')
    .nullable(),
  descricao: string().required('A descrição é obrigatória').nullable(),
});

export default validationSchema;
