import React from 'react';

import Container from '../../components/elements/Container';

import AeroportosList from './AeroportosList';

export default function Aeroportos() {
  return (
    <Container>
      <AeroportosList />
    </Container>
  );
}
