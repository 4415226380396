const initialValues = {
  quantidadeTentativaSMS: null,
  quantidadeTentativaEmail: null,
  tempoCronometroSMS: null,
  tempoCronometroEmail: null,
  tempoSessaoUsuarioCheckout: null,
  tempoSessaoAreaLogadaCliente: null,
  tempoSessaoAreaLogadaParceiro: null,
  tempoSessaoAreaLogadaAdmin: null, 
};

export default initialValues;
