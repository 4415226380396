import api from './api';
import axios from 'axios';

export const autenticar = async (Cpf, Senha) => {
  const req = {
    Cpf,
    Senha,
    TipoUsuarioId: 1,
  };
  const { data } = await api.post('/Usuario/Autenticar', req);
  return data;
};

export const getToken = async () =>
  axios.post('https://sso-hml.b2esistemas.com.br/api/token/get', {
    user: 'usr.svc.voepay',
    password: 'othFY!:Cb+lOjX2',
  });

export const enviarCodigoSenha = async (cpf) => {
  const { data } = await api.post('/Usuario/EnviarCodigoEsqueciMinhaSenha', {
    cpf,
    TipoUsuarioId: 1,
  });
  return data;
};

export const validarCodigo = async (Cpf, CodigoEsqueciMinhaSenha) => {
  const req = {
    Cpf,
    CodigoEsqueciMinhaSenha,
    TipoUsuarioId: 1,
  };

  const { data } = await api.post(
    '/Usuario/ValidarCodigoEsqueciMinhaSenha',
    req,
  );
  return data;
};

export const trocarSenha = async (cpf, senha) => {
  const req = {
    cpf,
    senha,
    TipoUsuarioId: 1,
  };

  const { data } = await api.post('/Usuario/TrocarSenha', req);
  return data;
};
